import { TEventGroupValues, TEventIds } from "src/data/events/types/eventTypes"
import { langKeys } from "src/i18n/langKeys"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"

export const EVENT_LIST_LIMIT = 20

export const sensorSwapEvents: TEventGroupValues[] = ["sensor_swap"]

export const mapEventGroup: { [Key in TEventGroupValues]: TEventIds[] } = {
  security_alarm: [
    "alarm_grace_period_expired",
    "alarm_muted_button_press",
    "alarm_silenced",
  ],
  alarm_recognition: ["alarm_heard"],
  out_of_battery: ["battery_empty"],
  low_battery: ["battery_low"],
  sensor_installed: ["device_install"],
  sensor_offline: ["device_offline"],
  sensor_online: ["device_online"],
  sensor_turned_off: ["device_power_off"],
  responder_sent: [
    "disturbance_dispatched_automatically",
    "disturbance_dispatched_manually",
  ],
  noise_issue_solved: ["disturbance_ended"],
  contacts_alerted: ["disturbance_level_4_automatically"],
  first_noise_alert: [
    "disturbance_first_notice",
    "disturbance_first_notice_response_service",
  ],
  second_noise_alert: [
    "disturbance_second_notice",
    "disturbance_second_notice_response_service",
  ],
  third_noise_alert: [
    "disturbance_level_4_manually",
    "disturbance_third_notice",
  ],
  window_break: ["glassbreak"],
  low_humidity: ["humidity_dropped_normal", "humidity_low"],
  high_humidity: ["humidity_high", "humidity_risen_normal"],
  crowd_detect: [
    "occupancy_scanned_device_limit_exceeded",
    "occupancy_scanned_device_limit_subceeded",
  ],
  response_partners_callout: [
    "response_service_dispatch_arrived",
    "response_service_dispatch_in_progress",
    "response_service_dispatch_completed",
    "response_service_dispatch_cancelled_by_user",
    "response_service_dispatch_cancelled_by_provider",
    "response_service_dispatch_cancelled_by_minut_while_in_progress",
    "response_service_dispatch_cancelled_by_minut_while_requested",
    "response_service_dispatch_requested_automatically",
    "response_service_dispatch_requested_manually",
  ],
  mold_risk: ["risk_of_mould"],
  sensor_swap: ["sensor_swap_failed", "sensor_swap_succeeded"],
  button_pressed: ["short_button_press"],
  sensor_attached: ["tamper_mounted", "tamper"],
  sensor_removed: ["tamper_removed", "tamper"],
  low_temperature: ["temperature_dropped_normal", "temperature_low"],
  high_temperature: [
    "temperature_high",
    "temperature_rapid_rise",
    "temperature_risen_normal",
  ],
  smoking_detection_smoking_detected: ["smoking_detection_smoking_detected"],
}

export const eventGroups: TSelectOption<TEventGroupValues>[] = [
  {
    label: langKeys.event_filter_security_alarm_label,
    value: "security_alarm",
  },
  {
    label: langKeys.event_filter_alarm_recognition_label,
    value: "alarm_recognition",
  },
  {
    label: langKeys.event_filter_out_of_battery_label,
    value: "out_of_battery",
  },
  {
    label: langKeys.event_filter_low_battery_label,
    value: "low_battery",
  },
  {
    label: langKeys.event_filter_sensor_installed_label,
    value: "sensor_installed",
  },
  {
    label: langKeys.event_filter_sensor_offline_label,
    value: "sensor_offline",
  },
  {
    label: langKeys.event_filter_sensor_online_label,
    value: "sensor_online",
  },
  {
    label: langKeys.event_filter_sensor_turned_off_label,
    value: "sensor_turned_off",
  },
  {
    label: langKeys.event_filter_responder_sent_label,
    value: "responder_sent",
  },
  {
    label: langKeys.event_filter_noise_issue_solved_label,
    value: "noise_issue_solved",
  },
  {
    label: langKeys.event_filter_contacts_alerted_label,
    value: "contacts_alerted",
  },
  {
    label: langKeys.event_filter_first_noise_alert_label,
    value: "first_noise_alert",
  },
  {
    label: langKeys.event_filter_second_noise_alert_label,
    value: "second_noise_alert",
  },
  {
    label: langKeys.event_filter_third_noise_alert_label,
    value: "third_noise_alert",
  },
  {
    label: langKeys.event_filter_window_break_label,
    value: "window_break",
  },
  {
    label: langKeys.event_filter_low_humidity_label,
    value: "low_humidity",
  },
  {
    label: langKeys.event_filter_high_humidity_label,
    value: "high_humidity",
  },
  {
    label: langKeys.event_filter_crowd_detect_label,
    value: "crowd_detect",
  },
  {
    label: langKeys.event_filter_response_partners_callout_label,
    value: "response_partners_callout",
  },
  {
    label: langKeys.event_filter_mold_risk_label,
    value: "mold_risk",
  },
  {
    label: langKeys.event_filter_sensor_swap_label,
    value: "sensor_swap",
  },
  {
    label: langKeys.event_filter_button_pressed_label,
    value: "button_pressed",
  },
  {
    label: langKeys.event_filter_sensor_attached_label,
    value: "sensor_attached",
  },
  {
    label: langKeys.event_filter_sensor_removed_label,
    value: "sensor_removed",
  },
  {
    label: langKeys.event_filter_low_temperature_label,
    value: "low_temperature",
  },
  {
    label: langKeys.event_filter_high_temperature_label,
    value: "high_temperature",
  },
  {
    label: langKeys.smoking_detection_smoking_detected_title,
    value: "smoking_detection_smoking_detected",
  },
]
